import * as React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelopeOpen, faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"


import Container from "./container"
import AnimationWrapper from "./animationWrapper"
import Title from "./title"
import AutoChevronContainer from "./autoChevronContainer"

import "./contactDataSection.css"

const ContactDataSection = ( {departmentsToShow} ) => {

  const data = useStaticQuery(
    graphql`
      query {
        contactData: allMdx(
          filter: {internal: {
            contentFilePath: {regex: "/contact-data/"}},
            frontmatter: {department: {ne: "dane-firmy"}}
          }
          sort: {fields: [frontmatter___order], order: ASC}
        ) {
          group(field: frontmatter___department) {
            edges {
              node {
                body
                frontmatter {
                  department
                  order
                  name
                  position
                  email
                  phone
                  phone_alt
                  phone_postfix
                  region
                  address
                }
                id
              }
            }
          }
        }
        contactDepartments: allMdx(
          filter: {internal: {contentFilePath: {regex: "/contact-departments/"}}}
          sort: { fields: [frontmatter___order], order: ASC }
        ) {
          nodes {
            frontmatter {
              name
              order
              slug
            }
          }
        }
      }
    `
  )

  // sort contacts and departments
  const sortedContacts = data.contactData.group.sort((a, b) => a.edges[0].node.frontmatter.department_order - b.edges[0].node.frontmatter.department_order);
  const departments = data.contactDepartments.nodes;
  sortedContacts.forEach(function(contact) {
    departments.forEach(function(department) {
      if(contact.edges[0].node.frontmatter.department === department.frontmatter.slug){
        contact.name = department.frontmatter.name;
        contact.order = department.frontmatter.order;
        contact.slug = department.frontmatter.slug;
      }
    });
  });
  sortedContacts.sort((a, b) => a.order - b.order);

  return (

    <section className="contact-data-section page-section">

      {sortedContacts.map((department, key) => (
        <div key={key}>
          {departmentsToShow.includes(department.slug) &&
            <AnimationWrapper id={department.slug}>

              <div className="title-container">
                <Title className="contact-section-title" title={department.name} />
                <AutoChevronContainer />
              </div>

              <div className="contact-data-wrapper">

                <Container className="contact-data-container">
                  {department.edges.map((contactCard) => (
                    <AnimationWrapper key={contactCard.node.id} className="contact-card" animationType="fadeInUp">
                      {contactCard.node.frontmatter.avatar &&
                        <div className="avatar-wrapper">
                          <GatsbyImage
                            image={contactCard.node.frontmatter.avatar.childImageSharp.gatsbyImageData}
                            alt={contactCard.node.frontmatter.name}
                          />
                        </div>
                      }
                      <div className="contact-data">
                        <div className="name">{contactCard.node.frontmatter.name}</div>
                        <div className="position">{contactCard.node.frontmatter.position}</div>
                        {contactCard.node.frontmatter.region && <div className="region">{contactCard.node.frontmatter.region}</div>}
                        {contactCard.node.frontmatter.address &&
                          <div className="address"><FontAwesomeIcon icon={faMapMarker} /><span> {contactCard.node.frontmatter.address}</span></div>
                          }
                        {contactCard.node.frontmatter.phone &&
                          <div className="phone"><FontAwesomeIcon icon={faPhone} /><a href={`tel:${contactCard.node.frontmatter.phone}`}> {contactCard.node.frontmatter.phone}</a></div>
                        }
                        {contactCard.node.frontmatter.phone_alt &&
                          <div className="phone-alt"><FontAwesomeIcon icon={faPhone} /><a href={`tel:${contactCard.node.frontmatter.phone_alt}`}> {contactCard.node.frontmatter.phone_alt} {contactCard.node.frontmatter.phone_postfix && <> {contactCard.node.frontmatter.phone_postfix}</>}
                          </a></div>
                        }
                        {contactCard.node.frontmatter.email &&
                          <div className="email"><FontAwesomeIcon icon={faEnvelopeOpen} /><a href={`mailto:${contactCard.node.frontmatter.email}`}> {contactCard.node.frontmatter.email}</a></div>
                        }
                      </div>
                    </AnimationWrapper>
                  ))}
                </Container>

                {/* <div className="lion-bg-container">
                  <GatsbyImage image={data.lion_bg.childImageSharp.gatsbyImageData} />
                </div> */}

              </div>
            </AnimationWrapper>
          }
        </div>
      ))}

      {/* <Title className="contact-section-title" title="&nbsp;" /> */}

    </section>
  )
}

ContactDataSection.propTypes = {
  exceptions: PropTypes.string,
};

ContactDataSection.defaultProps = {
  exceptions: '',
};

export default ContactDataSection