import * as React from "react"

import TextSection from "./textSection"
import Tile from "./tile"

const TileSection = ( {data, location} ) => {

    return (
      <div className={`${location}-tiles tile-section`}>

        <div className="text-section-container">
          {data.text_section.title_visible && (
            <TextSection className="description-medium" data={data.text_section} />
          )}
        </div>
        <div className="tile-grid">
          {data.tiles.map((tile, key) => (
              <Tile key={key} tile={tile} />
          ))}
        </div>

      </div>
    )
  }

export default TileSection