import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import CustomAniLink from "../components/customAniLink"
import AnimationWrapper from "./animationWrapper"
import styled from "styled-components"
import PropTypes from "prop-types"

import "./tile.css"

const Tile = ({ tile }) => {

  const TileTitleContainer = styled.div`
    color: ${tile.title_color || "rgba(255, 255, 255, 0.9)"};
  `
  const state = tile.active ? "active" : "disabled"

  return (
    <AnimationWrapper className={`tile ${tile.type} tile-${state}`}>
      <CustomAniLink Link={`${tile.active ? `${tile.url}` : "#"}`} >

        <GatsbyImage
          image={tile.image.childImageSharp.gatsbyImageData}
          alt={tile.title}
        />

        {tile.title &&
          <TileTitleContainer className="tile-title-container">
            <div className="tile-title">{tile.title} <FontAwesomeIcon icon={faArrowRight} /></div>
            {tile.description &&
              <div className="tile-desc">{tile.description}</div>
            }
          </TileTitleContainer>
        }
      </CustomAniLink>
    </AnimationWrapper>
  )
}

Tile.propTypes = {
  active: PropTypes.bool,
};

Tile.defaultProps = {
  active: true,
};


export default Tile