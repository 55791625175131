import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import AnimationWrapper from "./animationWrapper";

const StyledChevronContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  height: 60%;
  top: 20%;
  width: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 1540px;
`;

const StyledChevronWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  gap: ${({ gap }) => gap}px;
  width: ${({ width }) => width}%;
  max-width: calc(50vw - 100px);
  ${({ side }) => (side === "left" ? "left: 0;" : "right: 0;")};
`;

const StyledChevron = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 6px;
  height: 100%;
  width: calc(0.75vw + 6px);
  min-width: 15px;
  opacity: ${({ opacity }) => opacity};
  transition: opacity 0.3s;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 50.5%;
    width: 100%;
    background: #ed1c24;
    transform: skew(30deg, 0deg);
  }

  &:after {
    top: 50%;
    right: 0;
    transform: skew(-30deg, 0deg);
  }
`;

const AutoChevronContainer = () => {
  const chevronContainerRef = useRef(null);
  const [chevronCount, setChevronCount] = useState(0);
  const [vwPercentage, setVwPercentage] = useState(0);

  useEffect(() => {
    // Calculate 1% of the window width
    const calculatedVwPercentage = window.innerWidth / 100;
    setVwPercentage(calculatedVwPercentage);

    const calculateChevronCount = () => {
      if (chevronContainerRef.current) {
        const containerWidth = chevronContainerRef.current.clientWidth;
        const chevronWidth = 0.5 * vwPercentage + 6;
        const gap = 15;
        const estimatedChevronCount = Math.floor(
          containerWidth / (chevronWidth + gap)
        );
        setChevronCount(estimatedChevronCount);
      }
    };

    // Calculate chevron count whenever vwPercentage changes
    calculateChevronCount();

    // Attach resize listener
    const handleResize = () => {
      setVwPercentage(window.innerWidth / 100);
      calculateChevronCount();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [vwPercentage, setVwPercentage]);

  const chevronsLeft = Array.from({ length: chevronCount }).map((_, index) => (
    <StyledChevron
      key={index}
      opacity={index / (chevronCount - 1)} // Calculate opacity value
    />
  ));

  const chevronsRight = Array.from({ length: chevronCount }).map(
    (_, index) => (
      <StyledChevron
        key={index}
        opacity={1 - index / (chevronCount - 1)} // Reverse opacity value
      />
    )
  );

  return (
    <AnimationWrapper>
      <StyledChevronContainer>
        <StyledChevronWrapper
          className="chevron-wrapper"
          side="left"
          gap={15} // Adjust the gap value as needed
          width={43} // Adjust the width value as needed
          ref={chevronContainerRef}
        >
          {chevronsLeft}
        </StyledChevronWrapper>
        <StyledChevronWrapper
          className="chevron-wrapper"
          side="right"
          gap={15} // Adjust the gap value as needed
          width={43} // Adjust the width value as needed
          ref={chevronContainerRef}
        >
          {chevronsRight}
        </StyledChevronWrapper>
      </StyledChevronContainer>
    </AnimationWrapper>
  );
};

export default AutoChevronContainer;