import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import Header from "../components/header"
import Container from "../components/container"
import AnimationWrapper from "../components/animationWrapper"
import TextSection from "../components/textSection"
import TileSection from "../components/tileSection"
import ContactDataSection from "../components/contactDataSection"
import ContactSection from "../components/contactSection"
import Layout from "../components/layout"

// import "./produkty.css"

const ProduktyPage = ( {data, location} ) => {

  return (

    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      <AnimationWrapper>
        <Header pageName="Produkty" data={data.header} />
      </AnimationWrapper>

      <section id="produkty-tiles" className="page-section">
        <Container>
          <AnimationWrapper>
            <TileSection data={data.page.frontmatter.tiles_section} location="produkty" />
          </AnimationWrapper>
        </Container>
      </section>

      <ContactDataSection departmentsToShow="kopalnie" />

      <section id="contact-data" className="page-section">
        <ContactSection data={data.page.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "produkty" }, type: { eq: "page" } }) {
      frontmatter{
        title
        tiles_section{
          text_section{
            title
            title_visible
            align
            subtitle
            # icon{
            #   childImageSharp {
            #     gatsbyImageData(
            #       transformOptions: {fit: COVER},
            #       webpOptions: {quality: 95}
            #     )
            #   }
            # }
            button_text
            button_url
            button_style
            text
          }
          tiles{
            title
            type
            active
            url
            image{
              childImageSharp{
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
            description
          }
        }
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    header: file(relativePath: {eq: "page-headers/header-produkty.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 95
          transformOptions: {fit: COVER}
          webpOptions: {quality: 95}
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default ProduktyPage
